import React from 'react'
import ProjectsContainer from '../components/ProjectsContainer'


const DATA = [
    {
        title:'Merkezefendi Belediyesi Merkez Kütüphanesi',
        image:'/images/projects/biten-projeler/1.jpg'
    },

    {
        title:'DOC Denizli Auto Center',
        image:'/images/projects/biten-projeler/DOC-Denizli-Auto-Center.jpg'
    },

    {
        title:'Oya Ender Abalıoğlu Anadolu Lisesi',
        image:'/images/projects/biten-projeler/Oya-Ender-Abalioglu-Anadolu-Lisesi.jpg'
    },

    {
        title:'ZGK Gökşin Kimya',
        image:'/images/projects/biten-projeler/goksin_kimya.jpg'
    },

    {
        title:'İstek Denizli Okulları',
        image:'/images/projects/biten-projeler/istek-koleji.jpg'
    },


    {
        title:'Denizlipek Dinamik Fabrika',
        image:'/images/projects/biten-projeler/dinamik-fabrika.jpg'
    },


    {
        title:'Mansur Tekstil Fabrika',
        image:'/images/projects/biten-projeler/mansur-tekstil.jpg'
    },








    {
        title:'Asiyan Konutları',
        image:'/images/projects/biten-projeler/Asiyan-Konutlari.jpg'
    },
    {
        title:'Beyaz İnci Ağız ve Diş Sağlığı Merkezi',
        image:'/images/projects/biten-projeler/Beyaz-Inci-Agiz-ve-Dis-Sagligi-Merkezi.jpg'
    },
    {
        title:'Cedide Abalıoglu Anadolu İmam Hatip Lisesi',
        image:'/images/projects/biten-projeler/Cedide-Abalioglu-Anadolu-Imam-Hatip-Lisesi.jpg'
    },
    {
        title:'Colossae Thermal Hotel',
        image:'/images/projects/biten-projeler/Colossae-Thermal-Hotel.jpg'
    },

    {
        title:'Filiz Abalıoğlu Anadolu Lisesi',
        image:'/images/projects/biten-projeler/Filiz-Abalioglu-Anadolu-Lisesi.jpg'
    },
    {
        title:'Garanti Binası',
        image:'/images/projects/biten-projeler/Garanti-Binasi.jpg'
    },
    {
        title:'Müftü Ahmet Hulusi Cami ve Külliyesi',
        image:'/images/projects/biten-projeler/Muftu-Ahmet-Hulusi-Camii-ve-Kulliyesi.jpg'
    },
    {
        title:'Odak Hastanesi',
        image:'/images/projects/biten-projeler/Odak-Hastanesi.jpg'
    },
    
    {
        title:'Orhan Abalıoğlu Mesleki ve Teknik Anadolu Lisesi',
        image:'/images/projects/biten-projeler/Orhan-Abalioglu-Mesleki-ve-Teknik-Anadolu-Lisesi.jpg'
    },
    

    
    {
        title:'PAU Onkoloji Merkezi',
        image:'/images/projects/biten-projeler/PAU-Onkoloji-Merkezi (1).jpg'
    },
    
    
    {
        title:'Pınar Baha Abalıoglu Anadolu Lisesi',
        image:'/images/projects/biten-projeler/Pinar-Baha-Abalioglu-Anadolu-Lisesi.jpg'
    },
    
    
    {
        title:'Sınav Binası',
        image:'/images/projects/biten-projeler/Sinav-Binasi.jpg'
    },
    
    
    {
        title:'Taç Fabrika Satış-Mağazası',
        image:'/images/projects/biten-projeler/Tac-Fabrika-Satis-Magazasi.jpg'
    },
    
    
    {
        title:'U-Ofis',
        image:'/images/projects/biten-projeler/U-Ofis.jpg'
    },
    
    {
        title:'Vizyongöz Hastanesi',
        image:'/images/projects/biten-projeler/Vizyongoz-Hastanesi.jpg'
    },

    
]

const BitenProjeler = () => {
  return (
<ProjectsContainer DATA={DATA}/>
  )
}

export default BitenProjeler